import React, { Component } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

class Portfolio extends Component {
  render() {

    if(this.props.data){
      var projects = this.props.data.projects.map(function(projects){
        var projectImage = 'images/portfolio/'+projects.image;
        return <div key={projects.title} className="columns portfolio-item">
           <div className="item-wrap">
            <a href={projects.url} title={projects.title} target="_blank">
               <img alt={projects.title} src={projectImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{projects.title}</h5>
                     <p>{projects.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    const images = [
      {
         original: "images/image-1.jpg",
       },
       {
         original: 'images/image-2.jpg',

       },
       {
         original: 'images/image-3.jpg',
       },
       {
         original: 'images/image-4.jpg'
       },
       {
         original: 'images/image-5.jpg'
       },
       {
         original: 'images/image-6.jpg'
       },
       {
         original: 'images/image-7.jpg'
       }
       
    ];
    const someComponent = (props) => {
      // console.log(props.someProps.objectKey)
      return <div>{/* {props.someProps.objectKey} */}</div>;
    };

    return (
      <section id="portfolio">

      <div className="row">

         <div className="twelve columns collapsed">

            {/* <h1>Check Out Some of My Works.</h1> */}
            <ImageGallery
              items={images}
              // defaultImage={defaultImage}
              showBullets={true}
              showIndex={true}
              showThumbnails={false}
              lazyLoad={true}
              showPlayButton={true}
              renderCustomControls={someComponent}
            /> 

            {/* <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {projects}
            </div> */}
          </div>
      </div>
   </section>
    );
  }
}

export default Portfolio;
